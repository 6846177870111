/*** Color ***/

$primary-color: var(--primary-color);
$primary-lighter-color: var(--primary-lighter-color);
$primary-super-lighter-color: #F2FBF8;
$background-color: #f2f0f9;
$warn-lighter-color: #ffe3ed;
$warn-darker-color: #e5253e;
$pink: #ffe3ed;
$alert-success-color: #e0f0e2;
$alert-failure-color: #ffe3ed;
$alert-warning-color: #fff7e3;
$background-grey: #f2f2f2;
$border-grey: #d9d9d9;
$grey-light: #f6f6f6;
$error-base: #ED0529;
$green: #37A862;
$red: #E8384F;
$accent-color: #0072ce;

// $warn-lighter-color: var(--warn-lighter-color);
// $primary-lighter-color: var(--primary-lighter-color);

/*** Text Color ***/

$text-light: #717171;

/***************   */

/*** Sidenav ***/

$sidenav-dock-width: 60px;
$sidenav-width: 400px;

/*** Button ***/

$button-hover: #fcfaff;

/*** Font ***/

$font: var(--font);

/*** Colors ***/

$colors: (#C2185B, #008285, #C513DD, #286526, #ED0529, #6B3600, #461E7D, #667790);

@for $i from 1 through length($colors) {
  $color: nth($colors, $i);

  .background-color-#{$i} {
    background-color: $color;
  }

  .color-#{$i} {
    color: $color;
  }

  :root {
    --color-#{$i}: #{$color};
  }
}
