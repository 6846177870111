@import './utility/variables';

form {
  margin-top: 65px;
}

.form-divider {
  max-width: 1024px;
  margin-left: 0;
}

.form-header {
  color: $primary-color;
  max-width: 900px;
  margin: 30px 0 25px;
  border-bottom: 1px #ddd solid;
  padding: 0 0 15px 10px;
}

.form-subheader {
  color: #777;
  font-size: 14px;
  margin: -18px 0 25px;
  padding: 0 0 5px 10px;
}

.expandable-header {
  color: $primary-color;
  cursor: pointer;

  span {
    vertical-align: middle;
  }

  .mat-icon {
    vertical-align: middle;
    line-height: 25px;
  }
}

form.project-form {
  padding: 0 25px;
  margin-top: 2rem;
}

input, select, textarea {
  line-height: inherit;
  display: inline-block;
  font-family: "Roobert", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 6px 3px 6px 13px;
  resize: none;
  cursor: auto;
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;
  font-size: 15px;
  color: #333333;
  border: 1px solid #B8B8B8;
  border-radius: 3px;
  outline: 0;
  background-color: white;
}

input[type=checkbox] {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}


