@import 'src/app/style/utility/variables';

.background-primary {
  background-color: $primary-color !important;
}

.background-primary-lighter {
  background-color: $primary-lighter-color;
}

.background-pink {
  background-color: $pink;
}

.background-primary-super-lighter {
  background-color: $primary-super-lighter-color;
}

.background-grey {
  background-color: $text-light;
}

.background-grey-light {
  background-color: $grey-light;
}

.background-white {
  background-color: #fff;
}

.text-primary-color {
  color: $primary-color;
}

.text-lighter-color {
  color: $primary-lighter-color;
}

.text-error {
  color: $warn-darker-color !important;
}

.text-warn {
  color: $warn-darker-color;
}

.text-error-red {
  color: $error-base;
}

.text-pink {
  color: $pink;
}

.text-light-color {
  color: $text-light;
}
