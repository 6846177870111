.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.capitalize {
  text-transform: capitalize !important;
}

.text-bold {
  font-weight: bold;
}

.text-18 {
  font-size: 18px;
}

.text-10 {
  font-size: 10px;
}

.text-11 {
  font-size: 11px;
}

.text-12 {
  font-size: 12px;
}

.text-14 {
  font-size: 14px;
}

.text-16 {
  font-size: 16px;
}

.bold {
  font-weight: bold;
}

@for $size from 0 through 8 {
  $weight: ($size + 1) * 100;
  .font-weight-#{$weight} {
    font-weight: $weight;
  }
}
